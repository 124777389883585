@import url('../fonts/noir-pro/style.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
}
body {
  font-family: 'NoirPro, "Noto Sans JP", "Yu Gothic", "Meiryo", "Hiragino Kaku Gothic Pro",  Arial, sans-serif';
}
.sa-success {
  border-radius: 50%;
  border: 4px solid #a5dc86;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
}
.sa-success:after,
.sa-success:before {
  background: #fff;
  content: '';
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-success:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-success:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-success-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.25);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-success-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-success-tip,
.sa-success-long {
  background-color: #a5dc86;
  border-radius: 2px;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.sa-success-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
  animation: animateSuccessTip 0.75s;
}
.sa-success-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
  animation: animateSuccessLong 0.75s;
}

@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}

.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
}
.sa-error {
  border-radius: 50%;
  border: 4px solid #f27474;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
  animation: animateErrorIcon 0.5s;
}
.sa-error:after,
.sa-error:before {
  background: #fff;
  content: '';
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-error:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 0.75s ease-in;
}
.sa-error-x {
  display: block;
  position: relative;
  z-index: 2;
}
.sa-error-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(200, 0, 0, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-error-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-error-left,
.sa-error-right {
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
  background-color: #f27474;
  top: 37px;
  width: 47px;
}
.sa-error-left {
  left: 17px;
  transform: rotate(45deg);
  animation: animateXLeft 0.75s;
}
.sa-error-right {
  right: 16px;
  transform: rotate(-45deg);
  animation: animateXRight 0.75s;
}

@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

.wp-content .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-content .alignleft {
  float: left;
}

.wp-content .alignright {
  float: right;
}

.wp-content .wp-caption {
  border: 1px solid #ddd;
  text-align: center;
  background-color: #f3f3f3;
  padding-top: 4px;
  margin: 10px;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.wp-content .wp-caption img {
  margin: 0;
  padding: 0;
  border: 0 none;
}

.wp-content .wp-caption-dd {
  font-size: 11px;
  line-height: 17px;
  padding: 0 4px 5px;
  margin: 0;
}

.wp-content .sticky {
  background: #f7f7f7;
  padding: 0 10px 10px;
}

.wp-content .sticky h2 {
  padding-top: 10px;
}

.wp-content img {
  max-width: 100%;
  height: auto !important;
}
.wp-content img.size-full {
  width: 100%;
  height: auto;
}

.wp-content p {
  margin-top: 0;
  word-break: break-word;
}

.wp-content embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.wp-content .embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wp-content .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.wp-content .embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wp-content blockquote {
  font-weight: 100;
  font-size: 1.4rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0 auto;
  padding: 0.5rem;
}

.wp-content blockquote:before,
blockquote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

.wp-content blockquote:before {
  content: '“';
  left: -5rem;
  top: -2rem;
}

.wp-content blockquote:after {
  content: '”';
  right: -5rem;
  bottom: 1rem;
}

.wp-content cite {
  line-height: 3;
  text-align: left;
}
.wp-content strong,
.wp-content b {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}
.wp-content figure {
  margin: 0;
}
.wp-content a {
  color: #ed4d8b;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.scrollbar-container {
  position: relative;
  overflow-x: auto;
}

.scrollbar-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  cursor: pointer;
}

.scrollbar-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-container::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.scrollbar-container::-webkit-scrollbar-thumb:hover {
  background: #777;
}
.pure-link {
  color: #da423c;
}
.rule-content * {
  font-family: NoirPro, 'Noto Sans JP', 'Yu Gothic', Meiryo,
    'Hiragino Kaku Gothic Pro', Arial, sans-serif !important;
}
