@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('NoirPro-Regular.woff') format('woff'),
    url('NoirPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('NoirPro-Medium.woff') format('woff'),
    url('NoirPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('NoirPro-SemiBold.woff') format('woff'),
    url('NoirPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url('NoirPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('NoirPro-Bold.woff') format('woff'),
    url('NoirPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('icomoon.eot?8nng8j');
  src: url('icomoon.eot?8nng8j#iefix') format('embedded-opentype'),
    url('icomoon.ttf?8nng8j') format('truetype'),
    url('icomoon.woff?8nng8j') format('woff'),
    url('icomoon.svg?8nng8j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-department:before {
  content: '\e900';
  color: #475259;
}
.icon-employee:before {
  content: '\e901';
  color: #475259;
}
.icon-meeting-room:before {
  content: '\e902';
  color: #475259;
}
.icon-meeting-room2:before {
  content: '\e903';
  color: #475259;
}
.icon-daily-report:before {
  content: '\e904';
  color: #475259;
}
.icon-project:before {
  content: '\e905';
  color: #475259;
}
.icon-skill-report:before {
  content: '\e906';
  color: #475259;
}
.icon-time-correction:before {
  content: '\e907';
  color: #475259;
}
.icon-workspace:before {
  content: '\e908';
  color: #475259;
}
.icon-all-report:before {
  content: '\e909';
  color: #475259;
}
.icon-approve-list:before {
  content: '\e90a';
  color: #475259;
}
.icon-approve-list-2:before {
  content: '\e90b';
  color: #475259;
}
.icon-approve-time-correction:before {
  content: '\e90c';
  color: #475259;
}
.icon-dashboard:before {
  content: '\e90d';
  color: #475259;
  display: block;
}
.icon-employee-2:before {
  content: '\e90e';
  color: #475259;
}
.icon-event:before {
  content: '\e90f';
  color: #475259;
}
.icon-hr_related:before {
  content: '\e910';
  color: #475259;
}
.icon-Info-changes:before {
  content: '\e911';
  color: #475259;
}
.icon-listmagnifyingglass:before {
  content: '\e912';
  color: #475259;
}
.icon-Meeting-room:before {
  content: '\e913';
  color: #475259;
}
.icon-monthly-timeoff-report:before {
  content: '\e914';
  color: #475259;
}
.icon-mytime-offs:before {
  content: '\e915';
  color: #475259;
}
.icon-open-drawer:before {
  content: '\e916';
  color: #475259;
}
.icon-partner-company:before {
  content: '\e917';
  color: #475259;
}
.icon-Position:before {
  content: '\e918';
  color: #475259;
}
.icon-Quite-employees:before {
  content: '\e919';
  color: #475259;
}
.icon-rules:before {
  content: '\e91a';
  color: #475259;
}
.icon-send-email:before {
  content: '\e91b';
  color: #475259;
}
.icon-settings:before {
  content: '\e91c';
  color: #475259;
}
.icon-skill-category:before {
  content: '\e91d';
  color: #475259;
}
.icon-system-settings:before {
  content: '\e91e';
  color: #475259;
}
.icon-team-report:before {
  content: '\e91f';
  color: #475259;
}
.icon-template-settings:before {
  content: '\e920';
  color: #475259;
}
.icon-time--off-Templates:before {
  content: '\e921';
  color: #475259;
}
.icon-timesheet:before {
  content: '\e922';
  color: #475259;
}
.icon-work-status-report:before {
  content: '\e923';
  color: #475259;
}
.icon-actual-cost-report:before {
  content: '\e924';
  color: #475259;
}
.icon-approvel-Template:before {
  content: '\e925';
  color: #475259;
}
.icon-configuration:before {
  content: '\e926';
  color: #475259;
}
.icon-cost-report:before {
  content: '\e927';
  color: #475259;
}
.icon-remote-request:before {
  content: '\e928';
  color: #475259;
}
.icon-partner-division:before {
  content: '\e929';
  color: #475259;
}
.icon-partner-purchase-order:before {
  content: '\e92a';
  color: #475259;
}
.icon-resource-plan:before {
  content: '\e92b';
  color: #475259;
}
.icon-role:before {
  content: '\e92c';
  color: #475259;
}
.icon-skill:before {
  content: '\e92d';
  color: #475259;
}
.icon-time-correction-template:before {
  content: '\e92e';
  color: #475259;
}
.icon-vacation-report:before {
  content: '\e92f';
  color: #475259;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
