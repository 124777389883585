#time-grid .reactgrid-content .rg-pane .rg-cell.time-col {
  white-space: inherit;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  background: #fff !important;
  justify-content: center;
  font-weight: 500;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.time-cell {
  white-space: inherit;
  text-align: right;
  padding: 10px 6px;
  font-size: 13px;
  background: #fff !important;
  justify-content: center;
  overflow: unset;
}
.reactgrid-content .rg-pane .rg-cell.timeoff-project {
  white-space: inherit;
  font-size: 13px;
  cursor: pointer;
  background: #f6f6f6 !important;
  line-height: 1.2;
  padding-left: 15px !important;
  text-align: left !important;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.holiday.time-cell {
  white-space: inherit;
  text-align: right;
  padding: 10px 6px;
  font-size: 13px;
  color: #333 !important;
  background: #49b699 !important;
  justify-content: center;
  overflow: unset;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.is-correction .date-container {
  box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.06);
  background: #b64966 !important;
  color: #fff !important;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.has-corrected .date-container {
  box-shadow: 2px 3px 3px 1px rgba(0, 0, 0, 0.06);
  background: rgb(80, 182, 141) !important;
  color: #fff !important;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.weekend.time-cell {
  white-space: inherit;
  text-align: right;
  padding: 10px 6px;
  font-size: 13px;
  background: #eee !important;
  justify-content: center;
  color: #333 !important;
  overflow: unset;
  border-bottom-color: #ccc;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.is-correction.holiday {
  white-space: inherit;
  text-align: right;
  padding: 10px 6px;
  font-size: 13px;
  background: #49b699 !important;
  justify-content: center;
  color: #fff !important;
  overflow: unset;
  border-bottom-color: #999;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.holiday.weekend {
  white-space: inherit;
  text-align: right;
  padding: 10px 6px;
  font-size: 13px;
  background: #49b699 !important;
  justify-content: center;
  overflow: unset;
  border-bottom-color: #999;
}

#time-grid .reactgrid-content .rg-pane .rg-cell.time-cell {
  border-bottom-color: #e8e8e8 !important;
}

.reactgrid-content .rg-pane .rg-cell.employee-cell {
  border-bottom-color: #e8e8e8 !important;
  padding: 0;
}

#actual .reactgrid-content .rg-pane .rg-cell.project-name {
  white-space: inherit;
  text-align: left;
  padding: 10px;
  font-size: 13px;
  background: #fff !important;
  cursor: pointer;
}

#actual .reactgrid-content .rg-pane .rg-cell.project-header {
  white-space: inherit;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  background: #fff !important;
  font-weight: 500;
  justify-content: flex-start;
}
