.reactgrid-content .rg-pane .rg-cell {
  color: #333;
  text-align: center;
  padding: 0px;
}
.reactgrid-content .rg-pane .rg-cell.project-header {
  white-space: inherit;
  text-align: center;
  padding: 0px;
  font-size: 13px;
  background: #fff !important;
  justify-content: center;
}

.reactgrid-content .rg-pane .rg-cell.total-label {
  white-space: inherit;
  text-align: left;
  padding: 10px;
  font-size: 13px;
  background: #eee;
  justify-content: center;
}
/* .reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
  z-index: 9999;
  box-shadow: none;
}
.reactgrid-content .rg-pane.rg-pane-top {
  z-index: 100;
} */

.reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
  box-shadow: none;
}
.reactgrid-content .rg-pane.rg-pane-top {
  z-index: 8;
}

.rg-pane.rg-pane-top.rg-pane-left.rg-pane-shadow.shadow-top-left-corner {
  z-index: 10;
}

.reactgrid-content .rg-pane.rg-pane-left {
  z-index: 9;
}

.reactgrid-content .rg-pane .rg-cell.employee-cell {
  padding: 0;
}
.reactgrid-content .rg-pane .rg-cell.employee-total-cell {
  padding: 0;
  background: #f9f9f9 !important;
}

.reactgrid-content .rg-pane .rg-cell.cell-number-text {
  white-space: inherit;
  text-align: right;
  padding: 10px;
  font-size: 13px;
  background: #fff !important;
  cursor: pointer;
  justify-content: center;
}

.reactgrid-content .rg-pane .rg-cell.cell-number-text.red-border {
  border: 1px solid #da423c !important;
}

.reactgrid-content .rg-pane .rg-cell.cell-negative-number-text {
  white-space: inherit;
  text-align: right;
  padding: 10px;
  font-size: 13px;
  background: #fbeceb !important;
  cursor: pointer;
  justify-content: center;
}

.reactgrid-content .rg-pane .rg-cell.cell-number-text {
  white-space: inherit;
  text-align: right;
  padding: 10px;
  font-size: 13px;
  background: #fff !important;
  justify-content: center;
}

.rg-celleditor {
  text-align: center;
  color: #333;
  justify-content: center;
}
.reactgrid-content .rg-pane .rg-cell {
  color: #000 !important;
}

.reactgrid-content .rg-pane .rg-cell.skill-header {
  width: 30px;
  height: 200px;
  writing-mode: vertical-lr;
  font-size: 13px;
  padding: 10px;
  justify-content: center;
  background-color: #fff !important;
}
.reactgrid-content .rg-pane .rg-cell.colspan {
  z-index: 1;
  overflow: inherit !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto !important;
}
.reactgrid-content .rg-pane .rg-cell.employee-cell-skill {
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  background-color: #fff !important;
  text-align: left;
  padding-top: 4px;
}
.reactgrid-content .rg-pane .rg-header-cell.rg-cell.division-header {
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  padding-top: 3px;
}
.reactgrid-content .rg-pane .rg-cell.position-cell {
  overflow: hidden;
  padding: 0;
  border-right-color: #fff;
}

.reactgrid-content .rg-pane .rg-cell.custom-position-cell {
  overflow: hidden;
  padding: 0;
  background-color: #fff !important;
}

.reactgrid-content .rg-pane .rg-cell.divider-top {
  border-top: 2px solid #222 !important;
}

.reactgrid-content .rg-pane .rg-cell {
  touch-action: unset !important;
}

.reactgrid-content
  .rg-pane
  .rg-cell.rg-dropdown-cell-dif
  .rg-dropdown-select-container {
  border: 1px solid #da423c !important;
}

.rg-dropdown-menu > div::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  cursor: pointer;
}

.rg-dropdown-menu > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.rg-dropdown-menu > div::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.rg-dropdown-menu > div::-webkit-scrollbar-thumb:hover {
  background: #777;
}
#salary-table .reactgrid-content .rg-pane .rg-cell.rg-cell.cell-number-text {
  white-space: inherit;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  background: #fff !important;
  justify-content: center;
  color: #333;
}

#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  input[type='checkbox'] {
  display: none;
}

/* Hide the default checkbox */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  input[type='checkbox'] {
  display: none;
}

#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  input[type='checkbox'] {
  display: none;
}

/* Style the custom checkbox container */

/* Hide the default checkbox */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  input[type='checkbox'] {
  display: none;
}

#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  input[type='checkbox'] {
  display: none;
}

/* Style the custom checkbox container */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Style the cube container */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  span {
  height: 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style the front face of the cube */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  span::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 2px solid #ccc;
  position: absolute;
  transform: translateZ(10px); /* Move the front face forward */
}

/* Style the top face of the cube */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  span::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #333;

  position: absolute;
  transform: rotateX(90deg) translateZ(10px) translateY(-10px); /* Rotate and move the top face */
}

/* Style the checked state */
#salary-table
  .reactgrid-content
  .rg-pane
  .rg-cell.rg-cell.custom-check-box
  input[type='checkbox']:checked
  + span::before {
  background-color: #222; /* Green color for checked state */
}

#salary-table .reactgrid-content .rg-pane .rg-cell.rg-cell.failed {
  background: #ff6a70 !important;
}

#salary-table .reactgrid-content .rg-pane .rg-cell.rg-cell.success {
  background: #78ffd3 !important;
}

#salary-table .reactgrid-content .rg-pane .rg-cell.cell-number-text.valid {
  border: 2px solid rgb(53, 121, 248) !important;
}

.reactgrid-content .rg-pane .rg-cell.completed-project {
  white-space: inherit;
  text-align: right;
  padding: 10px;
  font-size: 13px;
  cursor: pointer;
  justify-content: center;
  background: #f6f6f6 !important;
}
